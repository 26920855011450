import React, { useState, useEffect } from "react";
import axios from "axios";

const DetailRekapHariIni = ({ url }) => {
  const [rekaphariini, setRekaphariini] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const formatDateForAPI = (date) => {
    const offset = date.getTimezoneOffset();
    const adjustedDate = new Date(date.getTime() - offset * 60 * 1000);
    return adjustedDate.toISOString().split("T")[0];
  };
  const formatTime = (time) => {
    if (!time) return "-";
    return time;
  };

  useEffect(() => {
    // Update your data fetching to include the selected date
    const fetchData = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await axios.post(
          url + "rekap-hari-ini-guru",
          {
            // Add your date parameter here
            nsm: JSON.parse(localStorage.getItem("dataGuru")).id_lembaga,
            date: formatDateForAPI(selectedDate),
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setRekaphariini(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [selectedDate]);

  return (
    <div style={{ padding: "10px", marginBottom: "80px" }}>
      <div className="card">
        <div className="card-header p-3">
          <div className="d-flex justify-content-between align-items-center">
            <h6 className="card-title m-0">Data Absensi Harian</h6>
            <div className="d-flex align-items-center gap-2">
              <button
                className="btn btn-sm btn-outline-info"
                onClick={() => {
                  const newDate = new Date(selectedDate);
                  newDate.setDate(selectedDate.getDate() - 1);
                  setSelectedDate(newDate);
                }}
              >
                <i className="fa fa-chevron-circle-left" aria-hidden="true"></i>
              </button>
              <span className="text-muted small">
                {selectedDate.toLocaleDateString("id-ID", {
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
              </span>
              <button
                className="btn btn-sm btn-outline-info"
                onClick={() => {
                  const newDate = new Date(selectedDate);
                  newDate.setDate(selectedDate.getDate() + 1);
                  setSelectedDate(newDate);
                }}
              >
                <i
                  className="fa fa-chevron-circle-right"
                  aria-hidden="true"
                ></i>
              </button>
            </div>
          </div>
        </div>
        <div className="card-body p-0" style={{ overflowY: "auto" }}>
          <table className="table table-hover table-sm mb-0">
            <thead className="bg-light sticky-top">
              <tr>
                <th className="px-3">Nama</th>
                <th className="px-3">Jam Masuk</th>
                <th className="px-3">Jam Keluar</th>
              </tr>
            </thead>
            <tbody>
              {rekaphariini
                .filter((item) => item && item.personnel)
                .map((item, index) => (
                  <tr key={`${item.personnel.id || index}`}>
                    <td className="px-3 align-middle">
                      <div className="d-flex align-items-center">
                        <div
                          className="rounded-circle bg-primary d-flex align-items-center justify-content-center text-white"
                          style={{
                            width: "24px",
                            height: "24px",
                            fontSize: "12px",
                          }}
                        >
                          {(item.personnel.full_name || "").charAt(0)}
                        </div>
                        <small className="ms-2">
                          {item.personnel.full_name || ""}
                        </small>
                      </div>
                    </td>
                    <td className="px-3 align-middle">
                      <span
                        className={`badge ${
                          item?.attendance?.check_in
                            ? "bg-success-subtle text-success"
                            : "bg-secondary-subtle text-secondary"
                        }`}
                      >
                        {formatTime(item?.attendance?.check_in) ||
                          "Belum Masuk"}
                      </span>
                    </td>
                    <td className="px-3 align-middle">
                      <span
                        className={`badge ${
                          item?.attendance?.check_out
                            ? "bg-danger-subtle text-danger"
                            : "bg-secondary-subtle text-secondary"
                        }`}
                      >
                        {formatTime(item?.attendance?.check_out) ||
                          "Belum Keluar"}
                      </span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default DetailRekapHariIni;
