import React, { useState, useEffect, lazy, Suspense, useCallback } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
  Navigate,
  useLocation
} from "react-router-dom";
import axios from "axios";
import ErrorBoundary from "./ErrorBoundary";
import { UnreadMessagesProvider } from './UnreadMessagesContext';
import UnreadMessagesListener from "./components/UnreadMessagesListener";
import Preloader from "./components/templates/Preloader";
import HomeGuru from "./components/templatesMobile/pagesMobile/HomeGuru";
import DetailAbsensi from "./components/templatesMobile/pagesMobile/DetailAbsensi";
import RekapHariIni from "./components/templatesMobile/pagesMobile/RekapHariIni";


// Lazy load components
const Login = lazy(() => import("./components/Login"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const DetailAbsen = lazy(() => import("./pages/DetailAbsen"));
const UploadSk = lazy(() => import("./pages/UploadSk"));
const Package = lazy(() => import("./pages/Package"));
const Tools = lazy(() => import("./pages/Tools"));
const TarikData = lazy(() => import("./pages/TarikData"));
const UploadData = lazy(() => import("./pages/UploadData"));
const ListMember = lazy(() => import("./pages/ListMember"));
const ListTransaksi = lazy(() => import("./pages/ListTransaksi"));
const Kitab = lazy(() => import("./pages/Kitab"));
const KelolaBuduk = lazy(() => import("./pages/KelolaBuduk"));
const Buduk = lazy(() => import("./pages/Buduk"));
const Ajuan = lazy(() => import("./pages/Ajuan"));
const File = lazy(() => import("./pages/File"));
const Video = lazy(() => import("./pages/Video"));
const Forbid = lazy(() => import("./pages/Forbid"));
const Notfound = lazy(() => import("./pages/Notfound"));
const Register = lazy(() => import("./pages/Register"));
const Invoice = lazy(() => import("./pages/Invoice"));
const Cetak = lazy(() => import("./components/inv/Cetak"));
const LoginMobile = lazy(() => import("./components/Mobile/LoginMobile"));
const Home = lazy(() => import("./components/templatesMobile/pagesMobile/Home"));
const DataSiswa = lazy(() => import("./components/templatesMobile/pagesMobile/DataSiswa"));
const System = lazy(() => import("./pages/System"));
const SyncRdm = lazy(() => import("./pages/SyncRdm"));
const DetailRombelBuduk = lazy(() => import("./pages/DetailRombelBuduk"));
const Pemadanan = lazy(() => import("./pages/Pemadanan"));
const MutasiBuduk = lazy(() => import("./pages/MutasiBuduk"));
const Tugas = lazy(() => import("./pages/Tugas"));
const Jadwal = lazy(() => import("./pages/Jadwal"));
const Profile = lazy(() => import("./pages/Profile"));
const Chat = lazy(() => import("./pages/Chat"));
const Absen = lazy(() => import("./pages/Absen"));

function App() {
  // const url = "http://localhost:8000/api/";
  const url = "https://api.sakti.biz.id/api/";
  const { id } = useParams();
  const { order_id } = useParams();
  const [role, setRole] = useState(null);
  const [admin, setAdmin] = useState(false);
  const [layananAktif, setLayananAktif] = useState("");
  const [paket, setPaket] = useState("");
  const [loading, setLoading] = useState(true);

  return (
    <Router>
      <AppContent
        url={url}
        id={id}
        role={role}
        admin={admin}
        layananAktif={layananAktif}
        paket={paket}
        loading={loading}
        setRole={setRole}
        setAdmin={setAdmin}
        setLayananAktif={setLayananAktif}
        setPaket={setPaket}
        setLoading={setLoading}
      />
    </Router>
  );
}

const AppContent = React.memo(({
  url,
  id,
  role,
  admin,
  layananAktif,
  paket,
  loading,
  setRole,
  setAdmin,
  setLayananAktif,
  setPaket,
  setLoading,
}) => {
  const currentLocation = useLocation();

  const fetchUser = useCallback(async () => {
    const token = localStorage.getItem("token");
    try {
      setLoading(true);
      const response = await axios.get(url + "me", {
        headers: { Authorization: `Bearer ${token}` },
      });

      setRole(response.data.user.role_id);
      setAdmin(response.data.user.is_admin);
      setLayananAktif(response.data.user.layanan_aktif);
      localStorage.setItem('service', response.data.user.layanan_active)
      setPaket(response.data.user.package);
    } catch (error) {
      if (error.response) {
        const message = error.response.data.message;
        console.log(message);
      }
    } finally {
      setLoading(false);
    }
  }, [url, setRole, setAdmin, setLayananAktif, setPaket, setLoading]);

  useEffect(() => {
    console.log("Current Path:", currentLocation.pathname);
    if (!currentLocation.pathname.match(/^\/(register|mobile\/.*)$/)) {
      console.log("Fetching user data...");
      fetchUser();
    } else {
      console.log("On public/mobile route, skipping fetchUser");
      setLoading(false);
    }
  }, [currentLocation.pathname, fetchUser]);

  if (loading) {
    return <Preloader />;
  }

  // Handle mobile routes
  if (currentLocation.pathname.startsWith("/mobile/")) {
    return (
      <Suspense fallback={<Preloader />}>
        <Routes>
          <Route path="/mobile/auth" element={<LoginMobile url={url} />} />
          <Route path="/mobile/home" element={<Home url={url} />} />
          <Route path="/mobile/data-siswa" element={<DataSiswa url={url} />} />

          <Route path="/mobile/guru/home" element={<HomeGuru url={url} />} />
          <Route path="/mobile/guru/detail-absensi" element={<DetailAbsensi url={url} />} />
          <Route path="/mobile/guru/rekap-hari-ini" element={<RekapHariIni url={url} />} />
        </Routes>
      </Suspense>
    );
  }

  // Handle public routes
  if (currentLocation.pathname === "/" || currentLocation.pathname === "/register") {
    return (
      <Suspense fallback={<Preloader />}>
        <Routes>
          <Route path="/register" element={<Register url={url} />} />
          <Route path="/" element={<Login />} />
        </Routes>
      </Suspense>
    );
  }

  // Handle protected routes
  return (
    <div>
      <ErrorBoundary>
        <UnreadMessagesProvider>
          <UnreadMessagesListener />
          <Suspense fallback={<Preloader />}>
            <Routes>
              <Route path="/chatting" element={<Chat url={url} />} />
              <Route path="/dashboard" element={<Dashboard url={url} />} />
              <Route path="/profile" element={<Profile url={url} />} />
              <Route path="/package" element={<Package url={url} />} />
              <Route path="/forbidden" element={<Forbid url={url} />} />
              <Route path="/tutorial" element={<Video url={url} />} />
              <Route path="/invoice/:order_id" element={<Invoice url={url} />} />
              <Route path="/cetakinvoice/:order_id" element={<Cetak url={url} />} />
              <Route path="/detail-absensi-admin/:id" element={<DetailAbsen url={url} />} />
              <Route path="/upload-sk" element={<UploadSk url={url} />} />

              {role === "1" && admin === "1" && (
                <Route path="/system" element={<System url={url} />} />
              )}
              {role === "1" && admin === "1" && (
                <>
                  <Route path="/listmember" element={<ListMember url={url} />} />
                  <Route path="/listtransaksi" element={<ListTransaksi url={url} />} />
                </>
              )}

              {layananAktif !== null && layananAktif !== "0" && paket !== null ? (
                <>
                  <Route path="/tools" element={<Tools url={url} />} />
                  <Route path="/absensi" element={<Absen url={url} />} />
                  <Route path="/tugas-ajar" element={<Tugas content="Set Tugas Mengajar" title="Set Tugas Guru" url={url} />} />
                  <Route path="/jadwal" element={<Jadwal content="Set Jadwal" title="Set Jadwal" url={url} />} />
                  <Route path="/kelolabuduk" element={<KelolaBuduk url={url} />} />
                  <Route path="/buduk" element={<Buduk url={url} />} />
                  <Route path="/sync-rdm" element={<SyncRdm url={url} />} />
                  <Route path="/padankan" element={<Pemadanan url={url} />} />
                  <Route path="/mutasibuduk" element={<MutasiBuduk url={url} />} />
                  <Route path="/listajuan" element={<Ajuan url={url} />} />
                  <Route path="/file" element={<File url={url} />} />
                  <Route path="/getsiswa" element={<TarikData title="TARIK DATA" content="SISWA" url={url} />} />
                  <Route path="/getguru" element={<TarikData content="GURU" url={url} />} />
                  <Route path="/getrombel" element={<TarikData content="ROMBEL" url={url} />} />
                  <Route path="/uploadsiswa" element={<UploadData content="SISWA" title="UPLOAD DATA SISWA" url={url} />} />
                  <Route path="/uploadortu" element={<UploadData content="ORTU" title="UPLOAD DATA ORANG TUA" url={url} />} />
                  <Route path="/uploadalamat" element={<UploadData content="ALAMAT" title="UPLOAD DATA ALAMAT" url={url} />} />
                  <Route path="/rombelauto" element={<UploadData content="Rombel" title="MEMASUKKAN SISWA KEDALAM ROMBEL" url={url} />} />
                  <Route path="/getsiswa/:id" element={<TarikData par={id} ket="detail" url={url} />} />
                  <Route path="/buduk-rombel/:id" element={<DetailRombelBuduk url={url} />} />
                  <Route path="/kitab" element={<Kitab url={url} />} />
                </>
              ) : (
                <Route path="*" element={<Navigate to="/forbidden" />} />
              )}

              {/* Catch all route */}
              <Route path="*" element={<Notfound url={url} />} />
            </Routes>
          </Suspense>
        </UnreadMessagesProvider>
      </ErrorBoundary>
    </div>
  );
});

export default App;