import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";

const DetailAbsensiGuru = ({ url }) => {
  const [absensiData, setAbsensiData] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [loading, setLoading] = useState(true);
  const [reason, setReason] = useState([]);

  const getReason = async () => {
    try {
      const response = await axios.post(`${url}reason`);
      setReason(response.data.data);
    } catch (error) {
      console.error("Error fetching reason:", error);
    }
  };

  useEffect(() => {
    fetchAbsensiData();
  }, [currentMonth]);

  useEffect(() => {
    getReason();
  }, []);

  const fetchAbsensiData = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const dataGuru = JSON.parse(localStorage.getItem("dataGuru"));

      const response = await axios.post(
        `${url}absensi/detail`,
        {
          personnel_id: dataGuru.personnel_id,
          month: currentMonth.getMonth() + 1,
          year: currentMonth.getFullYear(),
          nsm: dataGuru.id_lembaga,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setAbsensiData(response.data.data || []);
    } catch (error) {
      console.error("Error fetching absensi data:", error);
    } finally {
      setLoading(false);
    }
  };

  const previousMonth = () => {
    setCurrentMonth(
      new Date(currentMonth.setMonth(currentMonth.getMonth() - 1))
    );
  };

  const nextMonth = () => {
    setCurrentMonth(
      new Date(currentMonth.setMonth(currentMonth.getMonth() + 1))
    );
  };

  const formatDate = (date) => {
    return new Intl.DateTimeFormat("id-ID", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    }).format(new Date(date));
  };

  const [selectedItem, setSelectedItem] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [editForm, setEditForm] = useState({
    checkIn: "",
    checkOut: "",
    reasonId: "",
  });

  const formatTime = (value) => {
    // Remove any non-digit characters
    const cleanValue = value.replace(/\D/g, "");

    // Handle 4 digit input
    if (cleanValue.length >= 4) {
      let hours = cleanValue.slice(0, 2);
      let minutes = cleanValue.slice(2, 4);

      // Validate hours (00-23)
      hours = parseInt(hours) > 23 ? "23" : hours;

      // Validate minutes (00-59)
      minutes = parseInt(minutes) > 59 ? "59" : minutes;

      return `${hours}:${minutes}`;
    }

    return cleanValue;
  };

  const handleEdit = (item) => {
    setSelectedItem(item);
    setEditForm({
      checkIn: item.check_in || "",
      checkOut: item.check_out || "",
      reasonId: item.reason_id || "",
    });
    setShowModal(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "checkIn" || name === "checkOut") {
      setEditForm((prev) => ({
        ...prev,
        [name]: formatTime(value),
      }));
    } else {
      setEditForm((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post(`${url}update-attendance-dari-guru`, {
        id: selectedItem.id,
        check_in: editForm.checkIn,
        check_out: editForm.checkOut,
        reason_id: editForm.reasonId,
        date: selectedItem.date,
      });

      if (response.data.success) {
        Swal.fire({
          icon: "success",
          title: "Berhasil",
          text: "Data berhasil diupdate",
        });
        setShowModal(false);
        // Refresh data
        window.location.reload();
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Gagal",
        text: error.response?.data?.message || "Gagal mengupdate data",
      });
    }
  };

  return (
    <div style={{ padding: "20px", marginBottom: "80px" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "20px",
          background: "white",
          padding: "15px",
          borderRadius: "10px",
          boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
        }}
      >
        <button
          onClick={previousMonth}
          style={{
            background: "#e70303",
            color: "white",
            border: "none",
            padding: "8px 16px",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          &lt;
        </button>
        <h2 style={{ margin: 0, fontSize: "1.2rem", color: "#333" }}>
          {new Intl.DateTimeFormat("id-ID", {
            month: "long",
            year: "numeric",
          }).format(currentMonth)}
        </h2>
        <button
          onClick={nextMonth}
          style={{
            background: "#e70303",
            color: "white",
            border: "none",
            padding: "8px 16px",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          &gt;
        </button>
      </div>

      {loading ? (
        <div style={{ textAlign: "center", padding: "20px", color: "#666" }}>
          Loading...
        </div>
      ) : (
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          {absensiData.map((item) => (
            <div
              key={item.date}
              style={{
                background: "white",
                padding: "15px",
                borderRadius: "10px",
                boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                marginBottom: "10px", // Add this line for spacing
              }}
            >
              <div style={{ marginBottom: "10px" }}>
                <div
                  style={{
                    fontSize: "1rem",
                    color: "#333",
                    fontWeight: "bold",
                  }}
                >
                  {formatDate(item.date)}
                </div>
              </div>
              <div
                style={{ display: "flex", flexDirection: "column", gap: "5px" }}
              >
                {item.check_in && (
                  <div style={{ fontSize: "0.9rem", color: "#666" }}>
                    <span>Masuk: {item.check_in}</span>
                  </div>
                )}
                {item.check_out && (
                  <div style={{ fontSize: "0.9rem", color: "#666" }}>
                    <span>Pulang: {item.check_out}</span>
                  </div>
                )}
                {item.reason_id && (
                  <div style={{ fontSize: "0.9rem", color: "#666" }}>
                    <span
                      style={{
                        display: "inline-block",
                        padding: "4px 8px",
                        borderRadius: "4px",
                        backgroundColor: "#da2801",
                        color: "white",
                      }}
                    >
                      Ijin: {item.description}
                    </span>
                  </div>
                )}

                <button
                  onClick={() => handleEdit(item)}
                  style={{
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                    color: "#007bff",
                    padding: "5px",
                  }}
                >
                  <i className="fas fa-pencil-alt"></i>
                </button>
              </div>
            </div>
          ))}
        </div>
      )}

      <div
        className={`modal fade ${showModal ? "show" : ""}`}
        style={{ display: showModal ? "block" : "none" }}
        tabIndex="-1"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Absensi</h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => setShowModal(false)}
              ></button>
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <label className="form-label">Hari / Tanggal</label>
                <input
                  type="text"
                  className="form-control"
                  value={selectedItem ? formatDate(selectedItem.date) : ""}
                  disabled
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Jam Masuk</label>
                <input
                  type="text"
                  className="form-control"
                  name="checkIn"
                  value={editForm.checkIn}
                  onChange={handleInputChange}
                  placeholder="Masukkan 4 digit angka tanpa tanda :"
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Jam Pulang</label>
                <input
                  type="text"
                  className="form-control"
                  name="checkOut"
                  value={editForm.checkOut}
                  onChange={handleInputChange}
                  placeholder="Masukkan 4 digit angka tanpa tanda :"
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Keterangan</label>
                <select
                  className="form-control"
                  name="reasonId"
                  value={editForm.reasonId}
                  onChange={handleInputChange}
                >
                  <option value="">Pilih Keterangan</option>
                  {reason.map((ijin) => (
                    <option key={ijin.id} value={ijin.id}>
                      {ijin.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="modal-footer">
              {/* <button
                type="button"
                className="btn btn-secondary btn-sm"
                onClick={() => setShowModal(false)}
              >
                Tutup
              </button> */}
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={handleSubmit}
              >
                Simpan
              </button>
            </div>
          </div>
        </div>
      </div>
      {showModal && <div className="modal-backdrop fade show"></div>}
    </div>
  );
};

export default DetailAbsensiGuru;
