import React from "react";
import { NavLink, useLocation } from "react-router-dom";

const MenuBawahGuru = () => {
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <div>
      <footer className="bottom-nav">
        <ul className="d-flex align-items-center justify-content-around w-100 h-100">
          <li>
            <NavLink
              to="/mobile/guru/home"
              className={currentPath === "/mobile/guru/home" ? "active" : ""}
            >
              <img
                src={
                  currentPath === "/mobile/guru/home"
                    ? "/assets_mobile/svg/bottom-nav/home-active.svg"
                    : "/assets_mobile/svg/bottom-nav/home.svg"
                }
                alt="home"
              />
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/mobile/guru/detail-absensi"
              className={
                currentPath === "/mobile/guru/detail-absensi" ? "active" : ""
              }
            >
              <img
                src={
                  currentPath === "/mobile/guru/detail-absensi"
                    ? "/assets_mobile/svg/bottom-nav/category-active.svg"
                    : "/assets_mobile/svg/bottom-nav/category.svg"
                }
                alt="category"
              />
            </NavLink>
          </li>

          <li>
            <NavLink
              to="/mobile/guru/rekap-hari-ini"
              className={
                currentPath === "/mobile/guru/rekap-hari-ini" ? "active" : ""
              }
            >
              <img
                src={
                  currentPath === "/mobile/guru/rekap-hari-ini"
                    ? "/assets_mobile/svg/paperclip.svg"
                    : "/assets_mobile/svg/paperclip.svg"
                }
                alt="category"
              />
            </NavLink>
          </li>
        </ul>
      </footer>
    </div>
  );
};

export default MenuBawahGuru;
