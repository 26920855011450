import React from "react";
import Layouts from "./Layouts";
import DetailRekapHariIni from "../../Mobile/Guru/DetailRekapHariIni";

const RekapHariIni = ({ url }) => {
  return (
    <div>
      <Layouts user="guru">
        <DetailRekapHariIni url={url} />
      </Layouts>
    </div>
  );
};

export default RekapHariIni;
